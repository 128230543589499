<template>
	<div class="home">
		<v-container class="hero" fluid fill-height grid-list-md text-xs-center>
			<v-row align="center" justify="center" no-gutters>
				<div class="hero-text" cols="12" sm="10" md="8" lg="6">
					<h1>{{ $t("landing.hero.title") }}</h1>
					<span>
						{{ $t("landing.hero.description") }}
					</span>
					<div class="hero-buttons">
						<!-- <v-btn elevation="12" x-large @click="createTempRoom">{{
							$t("landing.hero.btns.create")
						}}</v-btn> -->
						<v-btn elevation="12" x-large to="/rooms">{{
							$t("landing.hero.btns.browse")
						}}</v-btn>
					</div>
				</div>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { createRoomHelper } from "@/util/roomcreator";

export default {
	name: "home",
	data() {
		return {};
	},
	computed: {
		cardHeight() {
			return 180;
		},
	},
	methods: {
		async createTempRoom() {
			await createRoomHelper(this.$store);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../variables.scss";

.home {
	width: 100%;
}

.hero {
	background: linear-gradient(
		217deg,
		rgb(125, 74, 239),
		rgb(227, 141, 174) 30%,
		rgb(247, 208, 109)
	);
	color: white;
	font-size: 22px;
	height: 100vh;
	min-height: 350px;
	display: flex;

	h1 {
		font-size: 52px;
	}

	@media only screen and (max-width: $xs-max) {
		h1 {
			font-size: 48px;
		}
	}

	.hero-text {
		display: flex;
		flex-direction: column;
		max-width: 600px;

		.hero-buttons {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.v-btn {
				background: transparent !important;
				// margin: 0 10px;
				@media only screen and (max-width: $md-max) {
					margin-top: 22px;
				}
			}

			@media screen and (max-width: $sm-max) {
				flex-direction: column;
			}
		}
	}
}
</style>
